import { template as template_f39804300bfe4830aa2e31b570f8ba3d } from "@ember/template-compiler";
const FKLabel = template_f39804300bfe4830aa2e31b570f8ba3d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_e1abb5abd72e4bb38ae4c0866837dbc9 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_e1abb5abd72e4bb38ae4c0866837dbc9(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
